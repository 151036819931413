
/* ================================ Social Bar =============================*/
.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999;
  }
  
  @media only screen and (max-width: 850px) {
    .icon-bar {
        display: none;
    }
  }
  
  /* Style the icon bar links */
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    z-index: 3;
  }
  
  /* Style the social media icons with color, if you want */
  .icon-bar a:hover {
    background-color: var(--color-background);
    color: var(--color-selected);
  }
  
  
  .facebook {
    background: #3B5998;
    color: white;
  }
  
  .twitter {
    background: #55ACEE;
    color: white;
  }
  
  .google {
    background: #dd4b39;
    color: white;
  }
  
  .linkedin {
    background: #007bb5;
    color: white;
  }
  
  .youtube {
    background: #bb0000;
    color: white;
  }

  .github {
    background: #7DBBE6;
    color: white;
  }

  .discord {
    background: #7289da;
    color: white;
  }