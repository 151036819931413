/* ================================ Education.js ================================ */
.card .card__degree {
    font-size: 1.35rem;
    margin-bottom: 15px;
}

@media screen and (min-width: 1200px) {
  #education .card {

    max-height: 475px !important;
  }
}
