/* ================================ Work.js ================================ */
#work {
    padding-top: 4rem;
    /* background-color: #02aab0;
    background-image: linear-gradient(135deg,#02aab0,#00cdac); */
    
    background-color: var(--color-wrkBG);
    background-image: linear-gradient(225deg, var(--color-wrkBG), var(--color-wrkBGGrad));
}

/* 875? */
@media screen and (min-width: 1200px) { 
    #work {
        padding-top: 10rem;
        padding-bottom: 25rem;
        /* -webkit-clip-path: polygon(0 20vh, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 20vh, 100% 0, 100% 100%, 0 100%); */
        -webkit-clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0 90%);
        clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0 90%);
    }
}
.main-timeline{
    /* font-family: 'Poppins', sans-serif; */
    position: relative;
}
.main-timeline:after{
    content: '';
    display: block;
    clear: both;
}
.main-timeline .timeline{
    width: 50%;
    margin: 0 0 20px 20px;
    float: right;
}


.main-timeline .timeline-content{
    color: var(--color-wrkLgtText);
    background: linear-gradient(to right, var(--color-cardLgtGrad), var(--color-cardDrkGrad));
    padding: 20px 20px 20px 100px;
    border-radius: 0 20px 20px 0;
    box-shadow: 0 5px 5px var(--color-wrkShadow);
    display: block;
    position: relative;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-year{
    color: var(--color-wrkDrkText);
    background-color: var(--color-wrkTabBG);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 130px;
    width: 130px;
    height: 100%;
    border-radius: 98px 0 0 20px;
    box-shadow: 0 5px 5px var(--color-wrkShadow);
    position: absolute;
    left: -130px;
    top: 0;
}
.main-timeline .timeline-icon{
    color: var(--color-wrkIcon);
    font-size: 50px;
    transform: translateY(-50%);
    position: absolute;
    left: 20px;
    top: 50%;
}
.main-timeline .title{
    font-size: 20px;
    font-weight: 600;
    /* text-transform: uppercase; */
    font-variant: small-caps;
    letter-spacing: 1px;
    margin: 15px 0 20px;
    color: var(--color-wrkTitle);
    text-align: center;
}
.main-timeline .description{
    font-size: 16px;
    letter-spacing: 1px;
    text-align: justify;
    margin-left: 25px;
    padding: 15px 25px;
    text-shadow: 0px 0px 85px var(--color-abtMainTextShadow) !important;
}
.main-timeline .description i {
    color: var(--color-wrkIcon);
}
.main-timeline .description li {
    padding-left: 15px;
    padding-top: 15px;
}
.main-timeline .timeline:nth-child(even){
    float: left;
    margin: 0 20px 20px 0;
}
.main-timeline .timeline:nth-child(even) .timeline-content{
    padding: 20px 100px 20px 20px;
    border-radius: 20px 0 0 20px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before{
    right:auto;
    left:0;
    clip-path: polygon(0 0, 100% 100%, 65% 0);
}
.main-timeline .timeline:nth-child(even) .timeline-year{
    left: auto;
    right: -130px;
    border-radius:0 98px 20px 0;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
    right: 30px;
    left: auto;
}
.main-timeline .timeline:nth-child(even) .timeline-content{
    background: linear-gradient(to right,var(--color-cardDrkGrad),var(--color-cardLgtGrad));
}
.main-timeline .timeline:nth-child(even) .timeline-year{ color: var(--color-wrkDrkText); }

/* 767? */
@media screen and (max-width:1200px){
    .main-timeline .timeline{
        width: 100%;
        padding: 50px 0 0 0;
    }
    .main-timeline .timeline .timeline-content,
    .main-timeline .timeline:nth-child(even) .timeline-content{
        padding: 20px 15px 20px 60px;
        border-radius: 0 20px 20px 20px;
        background: white;
    }
    .main-timeline .timeline .timeline-year,
    .main-timeline .timeline:nth-child(even) .timeline-year{
        font-size: 18px;
        border-radius: 15px 60px 0 0;
        box-shadow: none;
        width: 150px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: 0;
    }
    .main-timeline .timeline .timeline-icon,
    .main-timeline .timeline:nth-child(even) .timeline-icon{
        font-size: 28px;
        left: 15px;
        right: auto;
    }
}
@media screen and (max-width:576px){
    .main-timeline .title{ font-size: 18px; }
}