@import url(https://fonts.googleapis.com/css2?family=Baloo+2&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@400;900&display=swap);
/* ================================ App.js ================================ */
/* Main CSS Colors, Fonts and body. */

:root {
  --color-background: #f2f4f6;
  --color-forecolor: #505160;
  --color-highlightbg: #68829e;
  --color-selected: #aebd38;
  --color-deselected: #598234;

  --color-navBrand: #b9831d;
  --color-navBG: #f2f4f6;
  --color-navSelect: #aebd38;
  --color-navDeSelect: #598234;
  --color-navHover: #86aa44;

  --color-abtMainText: #ffffff;
  --color-abtMainTextShadow: #1d1705;
  --color-abtGradLight: #00cdac;
  --color-abtGradDark: #02aab0;
  --color-abtTypedText: #cbdf33;
  --color-abtButtonBG: transparent;
  --color-abtButtonOutline: #aebd38;
  --color-abtButtonText: #aebd38;
  --color-abtButtonTextHover: #ffffff;
  --color-abtButtonBGHover: #aebd38;
  --color-abtButtonOutlineHover: #aebd38;
  --color-abtButtonShadow: #c7df14a2;

  --color-wrkBG: #ceec097c; /* old color ceec09 */
  --color-wrkBGGrad: #02adb080; /* old color 02adb0  */
  --color-wrkTitle: #00615b;
  --color-wrkDrkText: #022717;
  --color-wrkLgtText: #262928;
  --color-wrkTabBG: #ffffff;
  --color-cardLgtGrad: #ffffffcc; /* brighter b4e40d, old color c0e251 */
  --color-cardDrkGrad: #ffffff; /* old color 7bdca6 */
  --color-wrkShadow: rgba(0, 0, 0, 0.5);
  --color-wrkIcon: rgba(49, 68, 44, 0.438);

  --color-contactBG: #2b3436;
  --color-contactBGGrad: #425462;
  --color-contactForecolor: #cacbda;
}

body {
  /*font-family: 'Roboto Condensed', sans-serif !important;*/
  font-family: "Baloo 2", cursive;
  background-color: #f2f4f6 !important;
  background-color: var(--color-background) !important;
  color: #505160 !important;
  color: var(--color-forecolor) !important;

  scroll-behavior: smooth;
}

/* ================================ Navbar.js ================================ */

.navbar {
    background-color: var(--color-navBG);
    -webkit-animation: fadein 2s;
            animation: fadein 2s;
    z-index: 9999;
  }
  
  @-webkit-keyframes fadein {
    from { 
      opacity: 0;
    }
    to { 
      opacity: 1;
    }
  }
  
  @keyframes fadein {
    from { 
      opacity: 0;
    }
    to { 
      opacity: 1;
    }
  }
  
  .container-fluid {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  
  nav a.navbar-brand {
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Encode Sans SC', sans-serif;
    font-weight: 900;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    color: var(--color-navBrand) !important;
  }
  nav a.nav-link {
    letter-spacing: 0.0375rem;
    cursor: pointer;
  }
  
  nav ul li {
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
  }
  
  .navbar-nav > li > a {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    margin-top: 0.25rem;
    color: var(--color-deselected) !important;
    border-top: 0.1875rem solid var(--color-navBG);
  }
  
  .navbar-nav .nav-link:hover {
    color: var(--color-navHover) !important;
    border-top: 0.1875rem solid var(--color-navHover);
  }
  
  /*.navbar-nav .nav-link.active {
    color: var(--color-navSelect) !important;
    border-top: 0.1875rem solid var(--color-navSelect);
  }
  
  /*.navbar-nav .nav-link {
    color: var(--color-navDeSelect) !important;
    border-top: 0.1875rem solid var(--color-navBG);
  }*/
  
  .navbar-nav > li > a.active, .navbar-nav > li > a:focus {
    color: var(--color-navSelect) !important;
    text-shadow: 0px 0px 25px var(--color-navSelect);
    border-top: 0.1875rem solid var(--color-navSelect);
  
  }

/* ================================ Social Bar =============================*/
.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999;
  }
  
  @media only screen and (max-width: 850px) {
    .icon-bar {
        display: none;
    }
  }
  
  /* Style the icon bar links */
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    z-index: 3;
  }
  
  /* Style the social media icons with color, if you want */
  .icon-bar a:hover {
    background-color: var(--color-background);
    color: var(--color-selected);
  }
  
  
  .facebook {
    background: #3B5998;
    color: white;
  }
  
  .twitter {
    background: #55ACEE;
    color: white;
  }
  
  .google {
    background: #dd4b39;
    color: white;
  }
  
  .linkedin {
    background: #007bb5;
    color: white;
  }
  
  .youtube {
    background: #bb0000;
    color: white;
  }

  .github {
    background: #7DBBE6;
    color: white;
  }

  .discord {
    background: #7289da;
    color: white;
  }
/* ================================ About.js ================================ */

#about {
    padding: 10% 10%;
    /*position: relative;*/
    background: linear-gradient(315deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.65)),url(../../static/media/joel-filipe-k8apfKm-Md4-unsplash.4b438212.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
  }
  
  @media screen and (min-width: 875px) { 
    #about {
      /* min-height: 29.6875rem !important; */
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    }
  
  }
  
  
  .hero-info {
    font-family: 'Encode Sans SC', sans-serif;
    /*font-family: 'Roboto Condensed', sans-serif;*/
    /*display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    */
    -webkit-animation: 1s ease-out 0s 1 slideInFromLeft;
            animation: 1s ease-out 0s 1 slideInFromLeft;
  }
  
  @-webkit-keyframes slideInFromLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes slideInFromLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes slideInFromRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  .hero-info img {
    height: auto;
    width: auto;
    max-height: 200px;
    max-width: 200px;
    display: block;
    border-radius: 50%;
    border: 2px solid var(--color-abtButtonOutline);
    box-shadow: 0 0 100px var(--color-abtButtonOutline);
  
  }
  
  .hero-info h1 {
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Encode Sans SC', sans-serif;
    color: var(--color-abtMainText);
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    font-weight: 900;
    font-size: 3.5vw;
  }
  
  .hero-info h2 {
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Encode Sans SC', sans-serif;
    /*font-size: 1.45rem;*/
    font-size: 2vw;
    color: var(--color-abtMainText);
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
  }
  
  @media screen and (max-width: 675px) { 
    .hero-info h1 {
      font-size: 1.50rem !important;
    }
    .hero-info h2 {
      font-size: 0.85rem !important;
    }
  }
  
  .text-about-gradient {
      background-image: linear-gradient(135deg, var(--color-abtGradDark), var(--color-abtGradLight));
      -webkit-background-clip: text;
              background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  .typed-text {
    margin-left: 0.35rem;
    /*font-family: 'Roboto Condensed', sans-serif;*/
    font-family: 'Encode Sans SC', sans-serif;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    color: var(--color-abtTypedText);
  }
  
  .btn-outline-primary {
    color: var(--color-abtButtonText) !important;
    background-color: var(--color-abtButtonBG) !important;
    background-image: none !important;
    border-color: var(--color-abtButtonOutline) !important;
  }
  
  .btn-outline-primary:hover {
    color: var(--color-abtButtonTextHover) !important;
    background-color: var(--color-abtButtonBGHover) !important;
    border-color: var(--color-abtButtonOutlineHover) !important;
  }
  
  .btn-outline-primary:focus,
  .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem var(--color-abtButtonShadow) !important;
  }
  
  .about-info p {
    font-family: "Baloo 2", cursive;
    color: var(--color-abtMainText);
    text-align: justify;
    text-justify: inter-word;
    text-shadow: 0px 0px 20px var(--color-abtMainTextShadow) !important;
    -webkit-animation: 1s ease-out 0s 1 slideInFromRight;
            animation: 1s ease-out 0s 1 slideInFromRight;
    font-size: 1.2rem;
  }
/* ================================ Projects.js ================================ */

/* @import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap"); */


#projects {
  font-family: "Baloo 2", cursive;
  font-size: 16px;
  color: #ffffff;
  text-rendering: optimizeLegibility;
  font-weight: normal;
  font-weight: initial;
}

a, a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

#pageHeaderTitle {
  margin: 5rem 0;
  /*text-transform: uppercase;*/
  text-align: center;
  font-size: 2.5rem;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
}

.card {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #000000;
  /* background: #f3f5f7; */
  background-color: #e1e5ea;
}
/* .card:nth-child(2n)::before {
  background-image: linear-gradient(30deg, #f2f4f6, transparent 50%);
} */

.card:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-image: linear-gradient(-70deg, #424242, transparent 50%); */
  opacity: 1;
  border-radius: 10px;
}

/* .card::before {
  background-image: linear-gradient(-30deg, #f2f4f6, transparent 50%);
} */

.card .t-dark {
  color: #18151f;
  /* color: #FFFFFF; */
}
.card a {
  color: inherit;
}
.card h1, .card .h1 {
  margin-top: 1.0rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  line-height: 1.2;
}
.card .small {
  font-size: 80%;
}
.card .card__title {
  font-size: 1.75rem;
}

.card__title:hover {
  color: #aebd38;
}

.card .card__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.card .card__img_link {
  display: contents;
}
.card .card__bar {
  width: 0px;
  min-height: 5px !important;
  margin: 0px 0 20px;
  border-radius: 5px;
  background-color: #aebd38;
  transition: width 0.2s ease;
}

.card:hover .card__bar {
  width: 100%;
}

.card .card__subtitle {
  margin-bottom: 0.55rem;
}
.card .card__subtitle i {
  margin-right: 1rem;
}

.card .card__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card .card__preview-txt {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-align: justify;
  /*height: 100%;*/
}
.card .card__tagbox {
  color: white;
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 25px 0 0 0;
  padding: 0;
  justify-content: center;
}
.card .card__tagbox .tag__item {
  display: inline-block;
  background: #adbd38b2;
  border-radius: 5px;
  border: 1px solid #AEBD38;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: background-color 0.3s;
}
.card .card__tagbox .tag__item:hover {
  background: #AEBD38;
}

.card .card__tagbox .tag__item i {
  margin-right: 5px;
}


/* ========================= Small Screens =========================*/

@media screen and (min-width: 769px) {
  .card {
    flex-wrap: inherit;
  }
  
  /* .card:nth-child(odd)::before {
    background-image: linear-gradient(85deg, #f2f4f6, transparent 50%);
  }

  .card:nth-child(even)::before {
    background-image: linear-gradient(291deg, #f2f4f6, transparent 50%);
  } */

  .card .card__title {
    font-size: 2rem;
  }
  .card .card__tagbox {
    justify-content: start;
  }
  .card .card__img {
    max-width: 35%;
    max-height: 100%;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .card .card__text {
    padding: 3rem;
    width: 100%;
  }
  .card .media.card__text:before {
    content: "";
    position: absolute;
    display: block;
    background: #18151f;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  /* .card:hover .card__img {
    transform: scale(1.1);
  } */
  .card:nth-child(even) {
    flex-direction: row;
  }
  .card:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .card:nth-child(even) .card__text::before {
    left: -25px !important;
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  .card:nth-child(odd) .card__text::before {
    right: -25px !important;
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
}

/* ========================= Large Screens =========================*/

@media screen and (min-width: 1024px) {
  .card__text {
    padding: 2rem 3.5rem;
  }

  .card__text:before {
    content: "";
    position: absolute;
    display: block;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  .card.dark .card__text:before {
    background: #18151f;
  }

  .card.light .card__text:before {
    background: #e1e5ea;
    /* background: #505160; */
  } 

}
/* ================================ BetaPastProjects.js ================================ */

.cardbox {
  position: relative;
  /* max-width: 90vw; */
  padding: 0 10px;
  margin: 20px 5%;
}

.scrollbox {
  /* snap mandatory on horizontal axis  */
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;
  align-items: stretch;

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;
}

.scrollbox .carditem {
  /* snap align center  */
  scroll-snap-align: center;
  margin: 10px;
  /* position: relative; */
  min-width: 95%;
  border-radius: 10px;
}

.scrollbox::-webkit-scrollbar {
  display: none; /* Chrome and Safari */
}

.cardbox .btn {
  position: absolute;

  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  height: 50px;
  width: 50px;

  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  background-color: rgba(0, 0, 0, 0.1);
  background-position: 50% 50%;
  background-repeat: no-repeat;

  fill: white;

  z-index: 1;

  opacity: 0.25;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.cardbox .btn:hover {
  opacity: 0.75;
  transition: opacity 0.55s ease-in-out;
  -moz-transition: opacity 0.55s ease-in-out;
  -webkit-transition: opacity 0.55s ease-in-out;
}

.cardbox .btn.next {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>');
  right: -4vw;
}

.cardbox .btn.prev {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"/></svg>');
  left: -4vw;
}

#projects {
  font-family: "Baloo 2", cursive;
  font-size: 16px;
  color: #ffffff;
  text-rendering: optimizeLegibility;
  font-weight: normal;
  font-weight: initial;
}

a,
a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

#pageHeaderTitle {
  margin: 5rem 0;
  /*text-transform: uppercase;*/
  text-align: center;
  font-size: 2.5rem;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
}

.carditem {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #000000;
  /* background: #f3f5f7; */
  background-color: #e1e5ea;
}
/* .card:nth-child(2n)::before {
  background-image: linear-gradient(30deg, #f2f4f6, transparent 50%);
} */

.carditem:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-image: linear-gradient(-70deg, #424242, transparent 50%); */
  opacity: 1;
  border-radius: 10px;
}

/* .card::before {
  background-image: linear-gradient(-30deg, #f2f4f6, transparent 50%);
} */

.carditem .t-dark {
  color: #18151f;
  /* color: #FFFFFF; */
}
.carditem a {
  color: inherit;
}
.carditem h1,
.carditem .h1 {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  line-height: 1.2;
}
.carditem .small {
  font-size: 80%;
}
.carditem .card__title {
  font-size: 1.75rem;
}

.card__title:hover {
  color: #aebd38;
}

.carditem .card__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.carditem .card__img_link {
  display: contents;
}
.carditem .card__bar {
  width: 0px;
  min-height: 5px !important;
  margin: 0px 0 20px;
  border-radius: 5px;
  background-color: #aebd38;
  transition: width 0.2s ease;
}

.carditem:hover .card__bar {
  width: 100%;
}

.carditem .card__subtitle {
  margin-bottom: 0.55rem;
}
.carditem .card__subtitle i {
  margin-right: 1rem;
}

.carditem .card__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.carditem .card__preview-txt {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-align: justify;
  /*height: 100%;*/
}
.carditem .card__tagbox {
  color: white;
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 25px 0 0 0;
  padding: 0;
  justify-content: center;
}
.carditem .card__tagbox .tag__item {
  display: inline-block;
  background: #adbd38b2;
  border-radius: 5px;
  border: 1px solid #aebd38;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: background-color 0.3s;
}
.carditem .card__tagbox .tag__item:hover {
  background: #aebd38;
}

.carditem .card__tagbox .tag__item i {
  margin-right: 5px;
}

/* ========================= Small Screens =========================*/

@media screen and (min-width: 769px) {
  .carditem {
    flex-wrap: inherit;
  }

  /* .card:nth-child(odd)::before {
    background-image: linear-gradient(85deg, #f2f4f6, transparent 50%);
  }

  .card:nth-child(even)::before {
    background-image: linear-gradient(291deg, #f2f4f6, transparent 50%);
  } */

  .carditem .card__title {
    font-size: 2rem;
  }
  .carditem .card__tagbox {
    justify-content: start;
  }
  .carditem .card__img {
    max-width: 35%;
    min-height: 100%;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }
  .carditem .card__text {
    padding: 3rem;
    width: 100%;
  }
  .carditem .media.card__text:before {
    content: "";
    position: absolute;
    display: block;
    background: #18151f;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  /* .card:hover .card__img {
    transform: scale(1.1);
  } */
  .carditem:nth-child(even) {
    flex-direction: row;
  }
  .carditem:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .carditem:nth-child(even) .card__text::before {
    left: -25px !important;
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    background: #e1e5ea;
  }
  .carditem:nth-child(odd) .card__text::before {
    right: -25px !important;
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
    background: #e1e5ea;
  }
}

/* ========================= Large Screens =========================*/

@media screen and (min-width: 1024px) {
  .card__text {
    padding: 2rem 3.5rem;
  }

  .card__text:before {
    content: "";
    position: absolute;
    display: block;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  .carditem.dark .card__text:before {
    background: #18151f;
  }

  .carditem.light .card__text:before {
    background: #e1e5ea;
    /* background: #505160; */
  }
}

/* ========================= Extra Large Screens =========================*/

/* @media screen and (min-width: 1725px) {
  .scrollbox {
    max-height: 65vh;
  }
} */

/* ================================ Work.js ================================ */
#work {
    padding-top: 4rem;
    /* background-color: #02aab0;
    background-image: linear-gradient(135deg,#02aab0,#00cdac); */
    
    background-color: var(--color-wrkBG);
    background-image: linear-gradient(225deg, var(--color-wrkBG), var(--color-wrkBGGrad));
}

/* 875? */
@media screen and (min-width: 1200px) { 
    #work {
        padding-top: 10rem;
        padding-bottom: 25rem;
        /* -webkit-clip-path: polygon(0 20vh, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 20vh, 100% 0, 100% 100%, 0 100%); */
        -webkit-clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0 90%);
        clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0 90%);
    }
}
.main-timeline{
    /* font-family: 'Poppins', sans-serif; */
    position: relative;
}
.main-timeline:after{
    content: '';
    display: block;
    clear: both;
}
.main-timeline .timeline{
    width: 50%;
    margin: 0 0 20px 20px;
    float: right;
}


.main-timeline .timeline-content{
    color: var(--color-wrkLgtText);
    background: linear-gradient(to right, var(--color-cardLgtGrad), var(--color-cardDrkGrad));
    padding: 20px 20px 20px 100px;
    border-radius: 0 20px 20px 0;
    box-shadow: 0 5px 5px var(--color-wrkShadow);
    display: block;
    position: relative;
}
.main-timeline .timeline-content:hover{ text-decoration: none; }
.main-timeline .timeline-year{
    color: var(--color-wrkDrkText);
    background-color: var(--color-wrkTabBG);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 130px;
    width: 130px;
    height: 100%;
    border-radius: 98px 0 0 20px;
    box-shadow: 0 5px 5px var(--color-wrkShadow);
    position: absolute;
    left: -130px;
    top: 0;
}
.main-timeline .timeline-icon{
    color: var(--color-wrkIcon);
    font-size: 50px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
    left: 20px;
    top: 50%;
}
.main-timeline .title{
    font-size: 20px;
    font-weight: 600;
    /* text-transform: uppercase; */
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    letter-spacing: 1px;
    margin: 15px 0 20px;
    color: var(--color-wrkTitle);
    text-align: center;
}
.main-timeline .description{
    font-size: 16px;
    letter-spacing: 1px;
    text-align: justify;
    margin-left: 25px;
    padding: 15px 25px;
    text-shadow: 0px 0px 85px var(--color-abtMainTextShadow) !important;
}
.main-timeline .description i {
    color: var(--color-wrkIcon);
}
.main-timeline .description li {
    padding-left: 15px;
    padding-top: 15px;
}
.main-timeline .timeline:nth-child(even){
    float: left;
    margin: 0 20px 20px 0;
}
.main-timeline .timeline:nth-child(even) .timeline-content{
    padding: 20px 100px 20px 20px;
    border-radius: 20px 0 0 20px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before{
    right:auto;
    left:0;
    -webkit-clip-path: polygon(0 0, 100% 100%, 65% 0);
            clip-path: polygon(0 0, 100% 100%, 65% 0);
}
.main-timeline .timeline:nth-child(even) .timeline-year{
    left: auto;
    right: -130px;
    border-radius:0 98px 20px 0;
}
.main-timeline .timeline:nth-child(even) .timeline-icon{
    right: 30px;
    left: auto;
}
.main-timeline .timeline:nth-child(even) .timeline-content{
    background: linear-gradient(to right,var(--color-cardDrkGrad),var(--color-cardLgtGrad));
}
.main-timeline .timeline:nth-child(even) .timeline-year{ color: var(--color-wrkDrkText); }

/* 767? */
@media screen and (max-width:1200px){
    .main-timeline .timeline{
        width: 100%;
        padding: 50px 0 0 0;
    }
    .main-timeline .timeline .timeline-content,
    .main-timeline .timeline:nth-child(even) .timeline-content{
        padding: 20px 15px 20px 60px;
        border-radius: 0 20px 20px 20px;
        background: white;
    }
    .main-timeline .timeline .timeline-year,
    .main-timeline .timeline:nth-child(even) .timeline-year{
        font-size: 18px;
        border-radius: 15px 60px 0 0;
        box-shadow: none;
        width: 150px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: 0;
    }
    .main-timeline .timeline .timeline-icon,
    .main-timeline .timeline:nth-child(even) .timeline-icon{
        font-size: 28px;
        left: 15px;
        right: auto;
    }
}
@media screen and (max-width:576px){
    .main-timeline .title{ font-size: 18px; }
}
/* ================================ Education.js ================================ */
.card .card__degree {
    font-size: 1.35rem;
    margin-bottom: 15px;
}

@media screen and (min-width: 1200px) {
  #education .card {

    max-height: 475px !important;
  }
}

/* ================================ Contact.js ================================ */

#contact {
    color: var(--color-contactForecolor);
    background-color: var(--color-contactBG);
    background-color: var(--color-contactBG);
    background-image: linear-gradient(27deg, var(--color-contactBG), var(--color-contactBGGrad));
}

#contact a {
    margin-top: 3rem;
    color:#7e8690;
}

#contact a:hover {
    color: rgb(230, 233, 240)
}

@media screen and (min-width: 875px) { 
    #contact {
        padding-top: 10rem;
        /* -webkit-clip-path: polygon(0 20vh, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 20vh, 100% 0, 100% 100%, 0 100%); */
        -webkit-clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0 100%);
        clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0 100%);
    }
}
