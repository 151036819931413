/* ================================ Projects.js ================================ */

/* @import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap"); */


#projects {
  font-family: "Baloo 2", cursive;
  font-size: 16px;
  color: #ffffff;
  text-rendering: optimizeLegibility;
  font-weight: initial;
}

a, a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

#pageHeaderTitle {
  margin: 5rem 0;
  /*text-transform: uppercase;*/
  text-align: center;
  font-size: 2.5rem;
  font-variant: small-caps;
}

.card {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #000000;
  /* background: #f3f5f7; */
  background-color: #e1e5ea;
}
/* .card:nth-child(2n)::before {
  background-image: linear-gradient(30deg, #f2f4f6, transparent 50%);
} */

.card:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-image: linear-gradient(-70deg, #424242, transparent 50%); */
  opacity: 1;
  border-radius: 10px;
}

/* .card::before {
  background-image: linear-gradient(-30deg, #f2f4f6, transparent 50%);
} */

.card .t-dark {
  color: #18151f;
  /* color: #FFFFFF; */
}
.card a {
  color: inherit;
}
.card h1, .card .h1 {
  margin-top: 1.0rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  line-height: 1.2;
}
.card .small {
  font-size: 80%;
}
.card .card__title {
  font-size: 1.75rem;
}

.card__title:hover {
  color: #aebd38;
}

.card .card__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.card .card__img_link {
  display: contents;
}
.card .card__bar {
  width: 0px;
  min-height: 5px !important;
  margin: 0px 0 20px;
  border-radius: 5px;
  background-color: #aebd38;
  transition: width 0.2s ease;
}

.card:hover .card__bar {
  width: 100%;
}

.card .card__subtitle {
  margin-bottom: 0.55rem;
}
.card .card__subtitle i {
  margin-right: 1rem;
}

.card .card__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card .card__preview-txt {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-align: justify;
  /*height: 100%;*/
}
.card .card__tagbox {
  color: white;
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 25px 0 0 0;
  padding: 0;
  justify-content: center;
}
.card .card__tagbox .tag__item {
  display: inline-block;
  background: #adbd38b2;
  border-radius: 5px;
  border: 1px solid #AEBD38;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  user-select: none;
  transition: background-color 0.3s;
}
.card .card__tagbox .tag__item:hover {
  background: #AEBD38;
}

.card .card__tagbox .tag__item i {
  margin-right: 5px;
}


/* ========================= Small Screens =========================*/

@media screen and (min-width: 769px) {
  .card {
    flex-wrap: inherit;
  }
  
  /* .card:nth-child(odd)::before {
    background-image: linear-gradient(85deg, #f2f4f6, transparent 50%);
  }

  .card:nth-child(even)::before {
    background-image: linear-gradient(291deg, #f2f4f6, transparent 50%);
  } */

  .card .card__title {
    font-size: 2rem;
  }
  .card .card__tagbox {
    justify-content: start;
  }
  .card .card__img {
    max-width: 35%;
    max-height: 100%;
    transition: transform 0.3s ease;
  }
  .card .card__text {
    padding: 3rem;
    width: 100%;
  }
  .card .media.card__text:before {
    content: "";
    position: absolute;
    display: block;
    background: #18151f;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  /* .card:hover .card__img {
    transform: scale(1.1);
  } */
  .card:nth-child(even) {
    flex-direction: row;
  }
  .card:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .card:nth-child(even) .card__text::before {
    left: -25px !important;
    transform: rotate(4deg);
  }
  .card:nth-child(odd) .card__text::before {
    right: -25px !important;
    transform: rotate(-4deg);
  }
}

/* ========================= Large Screens =========================*/

@media screen and (min-width: 1024px) {
  .card__text {
    padding: 2rem 3.5rem;
  }

  .card__text:before {
    content: "";
    position: absolute;
    display: block;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  .card.dark .card__text:before {
    background: #18151f;
  }

  .card.light .card__text:before {
    background: #e1e5ea;
    /* background: #505160; */
  } 

}