/* ================================ App.js ================================ */
/* Main CSS Colors, Fonts and body. */

@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@400;900&display=swap");

:root {
  --color-background: #f2f4f6;
  --color-forecolor: #505160;
  --color-highlightbg: #68829e;
  --color-selected: #aebd38;
  --color-deselected: #598234;

  --color-navBrand: #b9831d;
  --color-navBG: #f2f4f6;
  --color-navSelect: #aebd38;
  --color-navDeSelect: #598234;
  --color-navHover: #86aa44;

  --color-abtMainText: #ffffff;
  --color-abtMainTextShadow: #1d1705;
  --color-abtGradLight: #00cdac;
  --color-abtGradDark: #02aab0;
  --color-abtTypedText: #cbdf33;
  --color-abtButtonBG: transparent;
  --color-abtButtonOutline: #aebd38;
  --color-abtButtonText: #aebd38;
  --color-abtButtonTextHover: #ffffff;
  --color-abtButtonBGHover: #aebd38;
  --color-abtButtonOutlineHover: #aebd38;
  --color-abtButtonShadow: #c7df14a2;

  --color-wrkBG: #ceec097c; /* old color ceec09 */
  --color-wrkBGGrad: #02adb080; /* old color 02adb0  */
  --color-wrkTitle: #00615b;
  --color-wrkDrkText: #022717;
  --color-wrkLgtText: #262928;
  --color-wrkTabBG: #ffffff;
  --color-cardLgtGrad: #ffffffcc; /* brighter b4e40d, old color c0e251 */
  --color-cardDrkGrad: #ffffff; /* old color 7bdca6 */
  --color-wrkShadow: rgba(0, 0, 0, 0.5);
  --color-wrkIcon: rgba(49, 68, 44, 0.438);

  --color-contactBG: #2b3436;
  --color-contactBGGrad: #425462;
  --color-contactForecolor: #cacbda;
}

body {
  /*font-family: 'Roboto Condensed', sans-serif !important;*/
  font-family: "Baloo 2", cursive;
  background-color: var(--color-background) !important;
  color: var(--color-forecolor) !important;

  scroll-behavior: smooth;
}
