/* ================================ BetaPastProjects.js ================================ */

.cardbox {
  position: relative;
  /* max-width: 90vw; */
  padding: 0 10px;
  margin: 20px 5%;
}

.scrollbox {
  /* snap mandatory on horizontal axis  */
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;
  align-items: stretch;

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;
}

.scrollbox .carditem {
  /* snap align center  */
  scroll-snap-align: center;
  margin: 10px;
  /* position: relative; */
  min-width: 95%;
  border-radius: 10px;
}

.scrollbox::-webkit-scrollbar {
  display: none; /* Chrome and Safari */
}

.cardbox .btn {
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  height: 50px;
  width: 50px;

  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  background-color: rgba(0, 0, 0, 0.1);
  background-position: 50% 50%;
  background-repeat: no-repeat;

  fill: white;

  z-index: 1;

  opacity: 0.25;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.cardbox .btn:hover {
  opacity: 0.75;
  transition: opacity 0.55s ease-in-out;
  -moz-transition: opacity 0.55s ease-in-out;
  -webkit-transition: opacity 0.55s ease-in-out;
}

.cardbox .btn.next {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>');
  right: -4vw;
}

.cardbox .btn.prev {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"/></svg>');
  left: -4vw;
}

#projects {
  font-family: "Baloo 2", cursive;
  font-size: 16px;
  color: #ffffff;
  text-rendering: optimizeLegibility;
  font-weight: initial;
}

a,
a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

#pageHeaderTitle {
  margin: 5rem 0;
  /*text-transform: uppercase;*/
  text-align: center;
  font-size: 2.5rem;
  font-variant: small-caps;
}

.carditem {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #000000;
  /* background: #f3f5f7; */
  background-color: #e1e5ea;
}
/* .card:nth-child(2n)::before {
  background-image: linear-gradient(30deg, #f2f4f6, transparent 50%);
} */

.carditem:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-image: linear-gradient(-70deg, #424242, transparent 50%); */
  opacity: 1;
  border-radius: 10px;
}

/* .card::before {
  background-image: linear-gradient(-30deg, #f2f4f6, transparent 50%);
} */

.carditem .t-dark {
  color: #18151f;
  /* color: #FFFFFF; */
}
.carditem a {
  color: inherit;
}
.carditem h1,
.carditem .h1 {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  line-height: 1.2;
}
.carditem .small {
  font-size: 80%;
}
.carditem .card__title {
  font-size: 1.75rem;
}

.card__title:hover {
  color: #aebd38;
}

.carditem .card__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.carditem .card__img_link {
  display: contents;
}
.carditem .card__bar {
  width: 0px;
  min-height: 5px !important;
  margin: 0px 0 20px;
  border-radius: 5px;
  background-color: #aebd38;
  transition: width 0.2s ease;
}

.carditem:hover .card__bar {
  width: 100%;
}

.carditem .card__subtitle {
  margin-bottom: 0.55rem;
}
.carditem .card__subtitle i {
  margin-right: 1rem;
}

.carditem .card__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.carditem .card__preview-txt {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-align: justify;
  /*height: 100%;*/
}
.carditem .card__tagbox {
  color: white;
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 25px 0 0 0;
  padding: 0;
  justify-content: center;
}
.carditem .card__tagbox .tag__item {
  display: inline-block;
  background: #adbd38b2;
  border-radius: 5px;
  border: 1px solid #aebd38;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  user-select: none;
  transition: background-color 0.3s;
}
.carditem .card__tagbox .tag__item:hover {
  background: #aebd38;
}

.carditem .card__tagbox .tag__item i {
  margin-right: 5px;
}

/* ========================= Small Screens =========================*/

@media screen and (min-width: 769px) {
  .carditem {
    flex-wrap: inherit;
  }

  /* .card:nth-child(odd)::before {
    background-image: linear-gradient(85deg, #f2f4f6, transparent 50%);
  }

  .card:nth-child(even)::before {
    background-image: linear-gradient(291deg, #f2f4f6, transparent 50%);
  } */

  .carditem .card__title {
    font-size: 2rem;
  }
  .carditem .card__tagbox {
    justify-content: start;
  }
  .carditem .card__img {
    max-width: 35%;
    min-height: 100%;
    transition: transform 0.3s ease;
  }
  .carditem .card__text {
    padding: 3rem;
    width: 100%;
  }
  .carditem .media.card__text:before {
    content: "";
    position: absolute;
    display: block;
    background: #18151f;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  /* .card:hover .card__img {
    transform: scale(1.1);
  } */
  .carditem:nth-child(even) {
    flex-direction: row;
  }
  .carditem:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .carditem:nth-child(even) .card__text::before {
    left: -25px !important;
    transform: rotate(4deg);
    background: #e1e5ea;
  }
  .carditem:nth-child(odd) .card__text::before {
    right: -25px !important;
    transform: rotate(-4deg);
    background: #e1e5ea;
  }
}

/* ========================= Large Screens =========================*/

@media screen and (min-width: 1024px) {
  .card__text {
    padding: 2rem 3.5rem;
  }

  .card__text:before {
    content: "";
    position: absolute;
    display: block;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  .carditem.dark .card__text:before {
    background: #18151f;
  }

  .carditem.light .card__text:before {
    background: #e1e5ea;
    /* background: #505160; */
  }
}

/* ========================= Extra Large Screens =========================*/

/* @media screen and (min-width: 1725px) {
  .scrollbox {
    max-height: 65vh;
  }
} */
