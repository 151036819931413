/* ================================ About.js ================================ */

#about {
    padding: 10% 10%;
    /*position: relative;*/
    background: linear-gradient(315deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.65)),url('/src/Images/joel-filipe-k8apfKm-Md4-unsplash.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
  }
  
  @media screen and (min-width: 875px) { 
    #about {
      /* min-height: 29.6875rem !important; */
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    }
  
  }
  
  
  .hero-info {
    font-family: 'Encode Sans SC', sans-serif;
    /*font-family: 'Roboto Condensed', sans-serif;*/
    /*display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    */
    animation: 1s ease-out 0s 1 slideInFromLeft;
  }
  
  @keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .hero-info img {
    height: auto;
    width: auto;
    max-height: 200px;
    max-width: 200px;
    display: block;
    border-radius: 50%;
    border: 2px solid var(--color-abtButtonOutline);
    box-shadow: 0 0 100px var(--color-abtButtonOutline);
  
  }
  
  .hero-info h1 {
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Encode Sans SC', sans-serif;
    color: var(--color-abtMainText);
    font-variant: small-caps;
    font-weight: 900;
    font-size: 3.5vw;
  }
  
  .hero-info h2 {
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Encode Sans SC', sans-serif;
    /*font-size: 1.45rem;*/
    font-size: 2vw;
    color: var(--color-abtMainText);
    font-variant: small-caps;
  }
  
  @media screen and (max-width: 675px) { 
    .hero-info h1 {
      font-size: 1.50rem !important;
    }
    .hero-info h2 {
      font-size: 0.85rem !important;
    }
  }
  
  .text-about-gradient {
      background-image: linear-gradient(135deg, var(--color-abtGradDark), var(--color-abtGradLight));
      background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  .typed-text {
    margin-left: 0.35rem;
    /*font-family: 'Roboto Condensed', sans-serif;*/
    font-family: 'Encode Sans SC', sans-serif;
    font-variant: small-caps;
    color: var(--color-abtTypedText);
  }
  
  .btn-outline-primary {
    color: var(--color-abtButtonText) !important;
    background-color: var(--color-abtButtonBG) !important;
    background-image: none !important;
    border-color: var(--color-abtButtonOutline) !important;
  }
  
  .btn-outline-primary:hover {
    color: var(--color-abtButtonTextHover) !important;
    background-color: var(--color-abtButtonBGHover) !important;
    border-color: var(--color-abtButtonOutlineHover) !important;
  }
  
  .btn-outline-primary:focus,
  .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem var(--color-abtButtonShadow) !important;
  }
  
  .about-info p {
    font-family: "Baloo 2", cursive;
    color: var(--color-abtMainText);
    text-align: justify;
    text-justify: inter-word;
    text-shadow: 0px 0px 20px var(--color-abtMainTextShadow) !important;
    animation: 1s ease-out 0s 1 slideInFromRight;
    font-size: 1.2rem;
  }